"use strict";

global.map;
global.markers = [];
global.openInfoWindow = null;

global.initMap = function(locations) {
  // Make sure we have both googs and locations
  if ( !google || !locations) {
    console.log("Missing one of google or locations: ", !!google, " ", !!locations);
    return;
  }
  console.log(locations);
  var minLat, maxLat, minLon, maxLon;
  locations.forEach(function(location, index) {
    if (index) {
      minLat = Math.min(minLat, parseFloat(location.lat));
      minLon = Math.min(minLon, parseFloat(location.lon));
      maxLat = Math.max(maxLat, parseFloat(location.lat));
      maxLon = Math.max(maxLon, parseFloat(location.lon));
    } else {
      minLat = parseFloat(location.lat);
      minLon = parseFloat(location.lon);
      maxLat = parseFloat(location.lat);
      maxLon = parseFloat(location.lon);
    }
  });
  const mapCenter = {lat: (minLat + maxLat) / 2, lng: (minLon + maxLon) / 2};

  if (!global.map) {
    global.map = new google.maps.Map(document.getElementById('map-placeholder'), {
      center: mapCenter,
      zoom: 2
    });
  } else {
    global.map.setCenter(mapCenter);
    global.map.setZoom(2);
  }
  console.log(mapCenter);

  // Marker time!  Reset them if they exist, then re-add the new ones.
  global.markers.forEach(function(marker) {
    marker.setMap(null);
  })
  global.markers = [];

  locations.forEach(function(location) {
    // Make a new marker
    var newMarker = new google.maps.Marker({
      position: {lat: parseFloat(location.lat), lng: parseFloat(location.lon)},
      title: location.instructor +'\n'+ location.raw_address,
      map: global.map
    });

    // Generate the associated InfoWindow
    var infoWindow = new google.maps.InfoWindow({
        content: createInfoWindow(location)
    });

    // Associate the window with the marker
    newMarker.addListener('click', () => {
      if (openInfoWindow) {
        openInfoWindow.close();
      }
      infoWindow.open(global.map, newMarker);
      openInfoWindow = infoWindow
    })

    global.markers.push(newMarker);
  });
}

global.createInfoWindow = function(location) {
  const addressTypeIcon = (
    location.type == "APPROXIMATE" ?
    '<i class="far fa-question-circle"></i>"' :
    '<i class="fas fa-map-marked"></i>'
  );

  const address = (
    location.type == "APPROXIMATE" ?
    location.raw_address :
    location.google_address
  );
  const phoneNumbers = location.phone_numbers && location.phone_numbers.split(';').join(', ');
  const website = location.website || '';

  return `<section class="marker-info-window section">
    <div class="columns">
      <div class="column is-narrow"><i class="far fa-user fa-3x"></i></div>
      <div class="column">
        <p>${location.instructor}</p>
        <p>${location.city}, ${location.region}</p>
        <p><a target="_blank" href="${website}">${website}</a></p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-narrow">
        ${addressTypeIcon}
      </div>
      <div class="column">
        <p>
          <a target="_blank" href="https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}">
            ${address}
            </a>
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-narrow">
        <i class="fas fa-phone"></i>
      </div>
      <div class="column">
        <p>${phoneNumbers}</p>
      </div>
    </div>
  </div>
  `
};

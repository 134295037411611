import './googs.js';
import './main.css';
import { Elm } from './Main.elm';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import * as firebaseui from 'firebaseui';

import registerServiceWorker from './registerServiceWorker';

////////// Application and Firestore references
var app = Elm.Main.init({
  node: document.getElementById('elm-root')
});
var firestore = null;

////////// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDFe6xAXl1k35l_MtMqy3pFb4-k6_NBSOs',
  authDomain: 'sangkeumhan-178106.firebaseapp.com',
  databaseURL: 'https://sangkeumhan-178106.firebaseio.com',
  projectId: 'sangkeumhan-178106',
  messagingSenderId: '146683493856'
};

const firebaseUiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      authMethod: 'https://accounts.google.com',
      clientId: '146683493856-52kgol1hmbauci4baiu4fvgtjpckuolp.apps.googleusercontent.com',
      scopes: [
        'profile',
        'email',
        'openid'
      ]
    }, {
      provider: firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    }
  ],
  callbacks: {
    signInSuccessfulWithAuthResult: (authResult, redirectURl) => {
      if (authResult.user) {

      }
    }
  }
};

firebase.initializeApp(firebaseConfig);
firestore = firebase.firestore();
console.log('test');

////////// Application Proper


// Firebase Authentication
const ui = new firebaseui.auth.AuthUI(firebase.auth());

const onUserSignIn = (userModel) => {
  // If we're logged in, fetch the user permissions
  var permissionsDoc = firestore.collection('permission').doc(userModel.uid);
  permissionsDoc.get().then(doc => {
    console.log('Permissions fetched');
    console.log(doc.data());
    app.ports.updatedPermissions.send(doc.exists ? doc.data() : null);
  });
};

const onUserSignOut = () => {
  // Remove all cached permissions
  app.ports.updatedPermissions.send(null);
};

app.ports.login.subscribe(() => {
  firebase.auth().signInAnonymously().then( ()=> {
    console.log('Logged in!');
  }).catch(error => {
    console.log(error.code + ': ' + error.message);
  })
});

app.ports.logout.subscribe( () => {
  firebase.auth().signOut().then( () => {
    console.log('Logged out!');
  })
});

app.ports.fetchScrapeRecords.subscribe(() => {
  firestore.collection('scrape_record').get().then(querySnapshot => {
    console.log('Fetching records');
    var documents = []
    querySnapshot.forEach(doc => {
      var docData = doc.data();
      documents.push(Object.assign({}, {id: doc.id}, docData));
    });
    app.ports.updatedScrapeRecords.send(documents);
  });
});

app.ports.fetchLocations.subscribe( scrapeRecordId => {
  if (!scrapeRecordId) return;
  const scrapeRecordRef = firestore.collection('scrape_record').doc(scrapeRecordId);
  scrapeRecordRef.collection('school_location').get().then(querySnapshot => {
    console.log('Found locations: ' + querySnapshot.docs.length);
    var documents = [];
    querySnapshot.forEach(doc => {
      var docData = doc.data();
      documents.push(Object.assign({}, {id: doc.id}, docData));
    });
    app.ports.updatedSchoolLocations.send(documents);
    window.initMap(documents);
  })
});

app.ports.renderAuthUI.subscribe ( () => {
  setTimeout( () => {
    var authRenderPending = 5;

    console.log('Rendering auth UI');
    try {
      ui.start('#firebase-auth-container', firebaseUiConfig);
      authRenderPending = 0;
    } catch (error) {
      authRenderPending = authRenderPending - 1;
    }

  }, 10);
});

firebase.auth().onAuthStateChanged(user => {
  console.log('User state changed!');
  var userModel = {loggedIn: !!user};
  if (!!user) {
    userModel.uid = user.uid;
    userModel.photoUrl = user.photoURL;
  }
  console.log(userModel);
  app.ports.authChanges.send(userModel);

  // If we're logged in, fetch the current permissions
  user ? onUserSignIn(userModel) : onUserSignOut();
});
